// Components
import Sticker from "components/sticker/Sticker";

// Assets
import imgLinkedIn from "images/linkedin.png";
import imgAvatar from "images/avatar.png";
import imgBadge from "images/badge.png";
import imgRockstar from "images/rockstar.png";
import imgCatalogue from "images/catalogue.jpg";
import imgRDR2 from "images/rdr2.jpg";
import imgGTAV from "images/gtav.jpg";
import imgStrathmoreTherapies from "images/strathmore-therapies.png";
import imgClubMed from "images/clubmed.jpg";
import imgWOF from "images/wof.png";
import imgAbuDhabi from "images/abu-dhabi.jpg";
import imgHunter from "images/hunter.png";
import imgTannadice from "images/tannadice.jpg";
import imgReallyWild from "images/really-wild.jpg";
import imgScottishpower from "images/scottishpower.png";
import imgTimecop1983 from "images/timecop1983.jpg";
import imgTweetWorldCup from "images/twc.jpg";

import styles from "./App.module.scss";

function App() {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1>
          Ian <br />
          Bla<span>c</span>k
        </h1>
        <a
          href="https://www.linkedin.com/in/iblack10/"
          className={styles.linkedin}
        >
          <img src={imgLinkedIn} alt="Ian Black on LinkedIn" />
        </a>
      </header>
      <p>
        <strong>Creative front-end developer</strong> with 10+ years of industry
        experience. <em>User-centered</em>, <em>detail-oriented</em>,{" "}
        <em>empathetic</em>, and with a strong sense for visual design -{" "}
        <strong>striving to be the glue between design and development</strong>.
        Focused on delivering beautiful, responsive, and accessible web
        applications.
      </p>

      <div className={styles.portfolio}>
        <div className={styles.shiney}>
          <img src={imgBadge} className={styles.badge} alt="IB10 FC Badge" />
        </div>

        <Sticker
          name="Ian Black"
          title="Creative Front-End"
          isHolograph
          image={imgAvatar}
          number={2}
        />

        <Sticker
          name="Rockstar Games"
          title="Red Dead Redemption II"
          image={imgRDR2}
          number={3}
          url="https://socialclub.rockstargames.com/games/rdr2/overview"
        />

        <Sticker
          name="Rockstar Games"
          title="Grand Theft Auto V"
          image={imgGTAV}
          number={4}
          url="https://socialclub.rockstargames.com/games/gtav/"
        />

        <Sticker
          name="Rockstar Games"
          title="Social Club"
          image={imgRockstar}
          number={5}
          url="https://socialclub.rockstargames.com"
        />

        <Sticker
          name="Rockstar Games"
          title="Wheeler, Rawson &amp; Co."
          image={imgCatalogue}
          number={6}
          url="http://wheelerrawson.com/"
        />

        <Sticker
          name="Strathmore Therapies"
          title="Physiotherapy"
          image={imgStrathmoreTherapies}
          number={7}
          url="https://www.strathmoretherapies.co.uk"
        />

        <Sticker
          name="Hunter Boot"
          title="E-commerce"
          image={imgHunter}
          number={8}
        />

        <Sticker isPlaceholder number={9} />

        <Sticker
          name="ScottishPower"
          title="Energy"
          image={imgScottishpower}
          number={10}
        />

        <Sticker
          name="World of Football"
          title="Sport"
          image={imgWOF}
          number={11}
          url="https://www.worldoffootball.com/"
        />

        <Sticker isPlaceholder number={12} />

        <Sticker
          name="Visit Abu Dhabi"
          title="Travel"
          image={imgAbuDhabi}
          number={13}
        />

        <Sticker
          name="Club Med"
          title="Travel"
          image={imgClubMed}
          number={14}
        />

        <Sticker
          name="Tannadice"
          title="Fan Art"
          image={imgTannadice}
          number={15}
        />

        <Sticker
          name="Really Wild"
          title="Travel"
          image={imgReallyWild}
          number={16}
        />

        <Sticker
          name="Timecop 1983"
          title="Vectorisation / Fan Art"
          image={imgTimecop1983}
          number={17}
        />

        <Sticker
          name="Tweet World Cup"
          title="Sport"
          image={imgTweetWorldCup}
          number={18}
        />

        <Sticker isPlaceholder number={19} />
      </div>
    </div>
  );
}

export default App;

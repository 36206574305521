import React from "react";
import classNames from "classnames/bind";

// Styles
import styles from "./Sticker.module.scss";

// Constants
const cx = classNames.bind(styles);

// function getRandomArbitrary(min, max) {
//   return (
//     (Math.random() * (max - min) + min).toFixed(1) *
//     (Math.round(Math.random()) ? 1 : -1)
//   );
// }

function Sticker(props) {
  //const rotate = !props.isPlaceholder ? getRandomArbitrary(0, 1) : 0;

  const stickerClass = cx({
    sticker: true,
    placeholder: props.isPlaceholder,
  });

  const imageClass = cx({
    image: true,
    holograph: props.isHolograph,
  });

  const Tag = props.url ? "a" : "div";

  return (
    <Tag
      className={stickerClass}
      // style={{ transform: `rotateZ(${rotate}deg)` }}
      href={props.url}
    >
      <div className={imageClass}>
        {props.image ? <img src={props.image} alt={props.name} /> : null}
        {props.number && props.isPlaceholder ? (
          <div className={styles.number}>#{props.number}</div>
        ) : null}
      </div>
      <h2 className={styles.name}>{props.name}</h2>
      <h3 className={styles.title}>{props.title}</h3>
    </Tag>
  );
}

export default Sticker;
